@use "mixins";
@use "variables";
@use "sass:color";

::-ms-reveal {
  display: none;
  opacity: 0;
}
input::-webkit-caps-lock-indicator {
  content: none;
  opacity: 0;
}

html {
  @include mixins.scrollbar;
  font-size: variables.$font-size-default; /* root font size */
  font-family: variables.$font-default;
  background-color: variables.$color-background;
  color: variables.$color-text;
  touch-action: auto;

  &.no-scroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    scrollbar-gutter: stable;

    &-ios {
      position: fixed;

      body {
        position: absolute;
        width: 100%;
      }
    }
  }
}
.scrollblock{
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

#devcode_popup {
  .close {
    color: #000;
  }
}

.hide-chat {
  #chat-widget-container {
    display: none;
  }
}

[hidden] {
  display: none !important;
}

.center {
  text-align: center;
}

.link {
  @include mixins.font(12px, 600);
  position: relative;
  color: mixins.theme-var(variables.$color-gradient-text-var);
  text-decoration: none;
  transition: 0.1s;
  cursor: pointer;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: .2s;
    height: 1px;
    background-color: mixins.theme-var(variables.$color-gradient-text-var);
  }

  &:hover {
    color: color.scale(variables.$color-focus, $lightness:10%);

    @include mixins.langArabic {
      color: color.scale(variables.$color-rtl-text, $lightness:10%);
    }

    &:after {
      width: 0;
    }
  }

  &--secondary {
    color: variables.$color-secondary;
    border-bottom-color: variables.$color-secondary;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}

.container {
  width: 1560px;
  padding: 0 15px;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  box-sizing: border-box;

  &.inner {
    width: 1450px;
    padding: 0 50px;
  }

  &--redesign {
    max-width: 1050px;
    width: 100%;
  }
}

.account-container {
  width: 980px;
  max-width: 100%;
  margin: 0 auto;
}

.bonuses-container {
  width: 100%;
  margin: 0 auto;
}

// BUTTONS

// Button with animate and shadow
.btn-animate {
  $color-button: variables.$color-primary;
  $color-button-hover: color.scale(variables.$color-primary, $lightness: 10%);

  text-transform: uppercase;
  height: 52px;
  border-radius: 100px;
  padding: 0 30px;
  box-shadow: 0 7px 0 color.scale(#622E81, $lightness:-20%);
  transition: 0.1s all;

  &:hover {
    background-color: $color-button-hover;
  }

  &:active {
    transform: translateY(7px);
    box-shadow: 0 0 0 color.scale(variables.$color-primary, $lightness:-20%);
  }
}

.btn {
  @include mixins.font(10px, 600);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-sizing: border-box;
  height: 34px;
  cursor: pointer;
  border: 0;
  padding: 0 20px;
  color: variables.$color-primary;
  transition: 0.1s;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 34px;

  &.apple {
    @include mixins.langArabic() {
      -webkit-text-fill-color: mixins.theme-var(variables.$color-gradient-text-var) !important;

      &:before {
        -webkit-mask-composite: xor !important;
      }

      &:hover {
        -webkit-text-fill-color: mixins.theme-var(variables.$color-primary-var) !important;
      }
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  &:active {
    background-color: #fff;
  }

  &.big {
    @include mixins.font(18px, 600);
    height: 46px;
    border-radius: 46px;
    padding: 0 40px;
  }

  &--circle-simple {
    width: 36px;
    height: 36px;
    background-color: mixins.theme-var(variables.$color-focus-var);
    border-radius: 50%;
    padding: 0;
    color: variables.$color-background;

    i {
      display: inline-flex;
    }

    &:hover {
      background-color: color.scale(variables.$color-focus, $lightness: 50%);
    }
  }

  &--circle-default {
    width: 36px;
    height: 36px;
    background-color: #591789;
    border-radius: 50%;
    padding: 0;

    i {
      @include mixins.font(16px);
      display: inline-flex;
    }

    &:hover {
      background-color: variables.$color-light;
      color: #fff;
    }
  }

  &--simple-primary {
    --r: 40px; /* radius */
    --b: 2px; /* border width */
    text-transform: uppercase;
    background: mixins.theme-var(variables.$color-focus-var);
    font-family: variables.$font-default;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    border-radius: var(--r);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    text-decoration: none;
    cursor: pointer;
    height: 34px;
    transition: .3s;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: var(--r);
      border: var(--b) solid transparent;
      background: inherit;
      background-origin: border-box;
      background-clip: border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-composite: destination-out;
    }

    &:hover {
      text-shadow: 0 0 0 mixins.theme-var(variables.$color-secondary-var);
    }

    &.apple {
      -webkit-text-fill-color: mixins.theme-var(variables.$color-gradient-text-var) !important;

      &:before {
        -webkit-mask-composite: xor !important;
      }

      &:hover {
        -webkit-text-fill-color: mixins.theme-var(variables.$color-secondary-var) !important;
      }
    }
  }

  &--filled-primary {
    background: mixins.theme-var(variables.$color-focus-var);
    -webkit-background-clip: unset;
    background-clip: unset;
    -webkit-text-fill-color: unset;
    color: mixins.theme-var(variables.$color-background-var);
    border: none;
    transition: .3s all;
    @include mixins.font(10px, 600);

    @include mixins.media-above(variables.$bp-sm) {
      &:hover {
        background-color: variables.$color-secondary;
      }
    }
  }

  &--filled-pink {
    background-color: variables.$color-primary;
    color: variables.$color-background;
    @include mixins.font(10px, 600);

    @include mixins.media-above(variables.$bp-sm) {
      &:hover {
        background-color: variables.$color-secondary;
      }
    }
  }

  &--primary {
    background: mixins.theme-var(variables.$color-focus-var);
    -webkit-background-clip: unset;
    background-clip: unset;
    -webkit-text-fill-color: unset;
    color: mixins.theme-var(variables.$color-background-var);
    border: none;
    transition: .3s all;
    @include mixins.font(10px, 600);

    @include mixins.media-above(variables.$bp-sm) {
      &:hover {
        background-color: variables.$color-secondary;
      }
    }
  }

  &--secondary {
    background-color: transparent;
    border: 2px solid mixins.theme-var(variables.$color-gradient-text-var);
    color: variables.$color-text;

    &:hover {
      @include mixins.media-above(variables.$bp-sm) {
        border-color: #fff;
        background-color: transparent;
        color: #fff;
      }
    }

    &:active {
      border-color: #fff;
      background-color: transparent;
      color: #fff;
    }
  }

  &--redesign {
    @include mixins.font(12px, 600);
    height: 36px;

    &[disabled] {
      background-color: #3A3A3A;
      color: #000;
    }
  }

  &.btn-crooked {
    align-items: flex-start;
    background-image: url(/assets/img/btn/1.png), url(/assets/img/btn/2.png), url(/assets/img/btn/3.png);
    background-size: 100% 66px, 100% 71px, 100% 100%;
    background-repeat: no-repeat;
    background-position: top;
    background-color: transparent;
    height: 75px;
    line-height: 66px;
    padding: 0 40px;

    &:hover {
      background-color: transparent;
    }

    &:active {
      line-height: 68px;
      transform: translateY(7px);
      background-size: 100% 0, 100% 68px, 100% 68px;
      background-color: transparent;
    }
  }

  &--xmas {
    @include mixins.font(18px, 600);
    text-transform: uppercase;
    background-image: url("/assets/img/promo/christmas/btn-bg.png");
    background-repeat: no-repeat;
    background-color: transparent !important;
    background-size: 100% 100%;
    background-position: center;
    height: auto !important;
    padding: 20px 60px !important;

    &:hover {
      background-image: url("/assets/img/promo/christmas/btn-bg-hover.png");
    }
  }

  &--cancel {
    border: 2px solid variables.$color-primary;
    color: variables.$color-primary;
    background-color: transparent;

    &:hover {
      background-color: variables.$color-primary;
      color: #fff;
    }

    @include mixins.langArabic() {
      background: mixins.theme-var(variables.$color-focus-var);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      color: transparent;
      border: none;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        inset: 0;
        border-radius: 100px;
        border: 1px solid transparent;
        background: inherit;
        background-origin: border-box;
        background-clip: border-box;
        -webkit-mask: linear-gradient(#fff 0 0) padding-box,linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-composite: destination-out;
      }
    }
  }
}

// FORM CONTROL

.input-container {

  .btn {
    width: 100%;
  }

  & > app-form-input {
    width: 100%;
    margin-bottom: 15px;
  }
}

input[type=number] {
  -moz-appearance:textfield;
}

input[type=password]::-webkit-caps-lock-indicator {
  transform: translateX(90%);
}

.input {
  //@include autofill(#252628, $color-text-light);
  outline: none;
  cursor: pointer;
  background: transparent;
  color: variables.$color-text;
  border-radius: 0;
  box-sizing: border-box;
  height: 36px;
  border: 0;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: variables.$color-text;
    text-transform: uppercase;
  }

  &--simple {
    @include mixins.font(14px, 600);
    transition: 0.2s;
    height: 52px;
    padding: 20px 20px 2px 20px;
    background-color: #252628;
    border-radius: 4px;

    @include mixins.ngError {
      border-bottom-color: variables.$color-error;
    }

    @include mixins.media-below(variables.$bp-sm) {
      height: 40px;
      padding: 0 15px;
    }

    &:focus {
      background-color: color.scale(#252628, $lightness: 5%);
      max-width: 99.99%;
    }
  }

  &--outline {
    border: 2px solid variables.$color-primary;
    color: variables.$color-text-light;
    border-radius: 50px;
  }
}

textarea.input--simple {
  padding-top: 25px;
}

.file-loader {
  display: block;
  padding: 30px 20px;
  text-align: center;
  cursor: pointer;
  border: 2px dashed #553d67;
  background-color: #250241;

  @include mixins.media-below(variables.$bp-sm) {
    padding: 20px 15px;
  }

  input[type='file'] {
    display: none;
  }

  &__label {
    @include mixins.font(14px);

    @include mixins.media-below(variables.$bp-sm) {
      @include mixins.font(12px);
    }

    .link {
      @include mixins.font(14px, 500);

      @include mixins.media-below(variables.$bp-sm) {
        @include mixins.font(12px);
      }
    }
  }
}

.file-over {
  .file-loader {
    border-color: #fff;
  }
}


.form-control {

  &--container {
    display: flex;
    justify-content: space-between;

    app-form-input {
      width: 100%;

      &:not(:last-child) {
        margin-right: 13px;
      }
    }
  }
}

@keyframes checkboxCheck {
  0% {
    width: 26px;
    height: 26px;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.1s;
  }
  30% {
    transform: scale(0);
  }
  41% {
    width: 20px;
    height: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    border-right-width: 0;
    border-radius: 0;
    transform: translateX(4px) translateY(-4px) rotateZ(-45deg) scale(0);
  }
  70% {
    width: 20px;
    height: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    border-right-width: 0;
    border-radius: 0;
    transform: translateX(4px) translateY(-4px) rotateZ(-45deg) scale(1.3);
  }
  90% {
    width: 20px;
    height: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    border-right-width: 0;
    border-radius: 0;
    transform: translateX(4px) translateY(-4px) rotateZ(-45deg) scale(0.9);
  }
  100% {
    width: 20px;
    height: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    border-right-width: 0;
    border-radius: 0;
    border-color: mixins.theme-var(variables.$color-gradient-text-var);
    transform: translateX(4px) translateY(-4px) rotateZ(-45deg) scale(1);
  }
}

@keyframes checkboxUncheck {
  0% {
    width: 20px;
    height: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    border-right-width: 0;
    border-radius: 0;
    transform: translateX(4px) translateY(-4px) rotateZ(-45deg) scale(1);
  }
  50% {
    width: 20px;
    height: 10px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-top-width: 0;
    border-right-width: 0;
    border-radius: 0;
    transform: translateX(4px) translateY(-4px) rotateZ(-45deg) scale(0);
  }
  51% {
    width: 26px;
    height: 26px;
    transform: scale(0);
  }
  100% {
    width: 26px;
    height: 26px;
    transform: scale(1);
  }
}

.checkbox {
  @include mixins.flex(row, flex-start, center, nowrap);
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-height: 26px;
  min-width: 26px;

  &.checkbox--toggle {
    height: 30px;

    input[type='checkbox'] + .checkbox__view {
      animation: none !important;
    }

    input[type='checkbox']:checked + .checkbox__view:after {
      background-color: mixins.theme-var(variables.$color-gradient-text-var);
      left: 33px;
    }

    .checkbox__view {
      width: 60px;
      height: 30px;
      border-radius: 20px;
      background-color: variables.$color-background;
      border: 0;
      position: relative;
      display: block;

      &:after {
        display: block;
        width: 24px;
        height: 24px;
        background-color: variables.$color-secondary;
        position: absolute;
        content: '';
        top: 3px;
        left: 3px;
        border-radius: 50%;
        transition: 0.2s;
      }
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    &:checked {
      & + .checkbox__view {
        animation: checkboxCheck 0.35s forwards;
      }
    }

    &:not(:checked) {
      & + .checkbox__view {
        animation: checkboxUncheck 0.2s forwards;
      }
    }
  }

  input {
    &:checked {
      & + span {
        border-color: mixins.theme-var(variables.$color-gradient-text-var);

        i {
          transform: scale(1);
        }
      }
    }
  }

  &__view {
    box-sizing: border-box;
    cursor: pointer;
    animation: none !important;
    border-radius: 4px;
    position: relative;
    width: 26px;
    height: 26px;
    border: 2px solid #fff;
    transition: .2s;

    @include mixins.media-below(variables.$bp-sm) {
      width: 19px;
      height: 19px;
    }

    i {
      @include mixins.font(17px);
      color: mixins.theme-var(variables.$color-gradient-text-var);
      transition: .2s;
      transform: scale(0);
      display: inline-block;
      position: absolute;
      top: 1px;
      right: -2px;

      @include mixins.media-below(variables.$bp-sm) {
        @include mixins.font(13px);
      }
    }
  }

  &__label {
    @include mixins.font(14px, 400);
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    color: variables.$color-text;
    user-select: none;
    line-height: 1.5;
  }
}

.radio {
  display: flex;

  &--redesign {
    label {
      input {
        & + span {
          padding: 0;

          &:before {
            box-shadow: none !important;
            border: 1.5px solid mixins.theme-var(variables.$color-gradient-text-var);
            box-sizing: border-box;
            width: 22px;
            height: 22px;
          }

          &:after {
            content: ' ';
            position: absolute;
            width: 14px;
            height: 14px;
            background-color: mixins.theme-var(variables.$color-gradient-text-var);
            border-radius: 50%;
            top: 50%;
            left: 4px;
            transform: translate(0, -50%) scale(0);
            transition: .2s;
          }
        }
        &:checked + span {
          &:before {
            box-shadow: none !important;
          }

          &:after {
            transform: translate(0, -50%) scale(1);
          }
        }
      }
    }
  }

  $primary-color: variables.$color-focus;
  $text-color: color.mix(#000, $primary-color, 64%);

  label {
    @include mixins.font(14px, 500);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;

    input {
      position: absolute;
      left: -9999px;

      &:checked + span {
        &:before {
          box-shadow: inset 0 0 0 0.4375em $primary-color;
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      padding: 0.375em 0.75em 0.375em 0.375em;
      border-radius: 99em;
      transition: 0.25s ease;

      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em $primary-color;
      }
    }
  }
}

.select {
  @include mixins.font(14px, 500);
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  color: #000;

  @include mixins.langArabic {
    text-align: right;
  }

  &--secondary {
    .select {
      &__header {
        @include mixins.font(12px, 500);
        background-color: transparent;
        border: 4px solid variables.$color-primary;
        text-transform: uppercase;
        color: variables.$color-text-light;
        border-radius: 46px;

        &:after {
          border-color: variables.$color-secondary;
          top: 14px;
          width: 7px;
          height: 7px;
        }
      }
    }

    &.big {
      .select {
        &__header {
          color: #fff;
          height: 60px;
          line-height: 54px;
          padding: 0 75px 0 35px;

          &:after {
            top: 23px;
            right: 26px;
            border-color: mixins.theme-var(variables.$color-gradient-text-var);
          }
        }
      }
    }
  }

  &__header {
    display: block;
    line-height: 46px;
    height: 46px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: variables.$color-background-light;

    color: variables.$color-secondary;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    padding: 0 45px 0 15px;
    border-radius: 3px;
    letter-spacing: 1px;

    @include mixins.langArabic {
      padding: 0 15px 0 45px;
    }

    a {
      text-decoration: inherit;
      color: inherit;
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    }

    &.outline {
      border: 2px solid variables.$color-primary;
      color: variables.$color-text-light;
      border-radius: 50px;
      background-color: transparent;
      line-height: 44px;
    }

    @include mixins.langArabic {
      &:after {
        right: unset;
        left: 20px;
      }
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      border: 2px solid mixins.theme-var(variables.$color-gradient-text-var);
      border-top-width: 0;
      border-left-width: 0;
      transform: translateY(-50%) rotateZ(45deg);
      right: 20px;
      top: calc(50% - 2px);
    }
  }

  &__dropdown {
    visibility: hidden;
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%) scale(0.9, 0);
    width: 100%;
    transition: 0.2s;
    opacity: 0;
    padding: 5px 0;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    transform-origin: center;
    box-shadow: 0 15px 0 -16px variables.$color-dark;
    z-index: 1;

    &-container {
      @include mixins.scrollbar;
      @include mixins.rtlDirection();
      overflow: auto;
      max-height: 180px;

      &::-webkit-scrollbar {
        background-color: #fff;
      }
    }

    &.open {
      visibility: visible;
      transform: translateY(-50%) scale(1, 1);
      opacity: 1;
      pointer-events: all;
      box-shadow: 0 15px 18px -16px variables.$color-dark;
      z-index: 12;
    }
  }

  &__option {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    box-sizing: border-box;
    padding: 8px 15px;
    transition: 0.1s;

    &:hover {
      background-color: color.scale(#fff, $lightness: -10%);
    }

    @include mixins.langArabic() {
      text-align: right;
    }
  }
}


.text {
  h1 {
    font-size: 18px;
    margin: 0;
  }
}


.password-toggle {

  &.active {
    color: variables.$color-secondary;
  }
}


.error-tooltip {
  @include mixins.font(12px, 700);
  color: variables.$color-primary;
  position: fixed;
  background-color: variables.$color-background;
  border-radius: 6px;
  padding: 15px;
  max-width: 170px;
  user-select: none;
  opacity: 0;
  transition: opacity 0.3s, transform 0.2s;
  z-index: 9999;
  pointer-events: none;

  &:not(.right) {
    transform: translateY(20px);

    &:after {
      left: 20px;
      top: 100%;
    }
  }

  &.right {
    transform: translateX(-20px);
  }


  &.visible {
    transform: scale(1) translateX(0px) translateY(0px);
    opacity: 1;
    pointer-events: all;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: variables.$color-background;
    width: 10px;
    height: 10px;
    transform: rotateZ(45deg) translateY(-60%);
    left: -8px;
    top: 50%;
  }

  &--informer {
    &:not(.right) {
      &:after {
        left: auto;
        right: 20px;
        top: 100%;
      }
    }
  }
}

.input-item {
  &.has-icon {
    .input {
      &--simple {
        padding-right: 60px;
      }

      &[name="nickname"] {
        @include mixins.media-below(variables.$bp-sm) {
          padding: 10px 75px 0 20px;
        }
      }
    }
  }
}

#credential_picker_iframe{
  bottom: 15px !important;
}

.text-gradient {
  @include mixins.langArabic() {
    @include mixins.textFill();

    &.apple {
      -webkit-text-fill-color: mixins.theme-var(variables.$color-gradient-text-var) !important;

      &:before {
        -webkit-mask-composite: xor !important;
      }
    }
  }
}
